/*
    June 9th 2022
    useSmartContract hook
    William Doyle
    Allows for the use of a smart contract without concern for the details
*/
import React from 'react'
import { useSelector } from 'react-redux'
import { ethers } from 'ethers';
import getSigner from '../functions/getSigner';

export default function useSmartContract(functionName: string, ...args: any[]) {
    const cd = useSelector((state: any) => state.contractData.contractData)
    const [contract, setContract] = React.useState(null as ethers.Contract | null)
    const [result, setResult] = React.useState(null as any);

    React.useEffect(() => {
        if (!cd)
            return
        (async () => {
            const signer = await getSigner()
            const contractWithSigner = new ethers.Contract(cd.address, cd.abi, signer);

            setContract(contractWithSigner)
        })()
    }, [cd])

    React.useEffect(() => {
        if (!contract)
            return

        (async () => {
            try { // 
                const r = await contract[functionName](...args)
                setResult(r)
            } catch (e) {
                console.log(`AN ERROR OCCURED WHILE USING THE useSmartContract HOOK. THE FUNCTION NAME WAS ${functionName} AND THE ARGS WERE ${args}`)
                console.log(`FUNCTION NAME --> `, functionName)
                console.log(`ARGS --> `, args)
                throw e
            }
        })()
    }, [contract, functionName, args])

    return result
}