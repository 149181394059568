import React from "react"
import { ethers } from "ethers";

const chainId_to_name = {
    '1': { name: 'Ethereum Mainnet', symbol: 'ETH' },
    '137': { name: 'Polygon Mainnet', symbol: 'MATIC' },
    '11155111': { name: 'Sepolia', symbol: 'sepETH' },
}

export default function useNativeTokenName() {

    const [chainId, setChainId] = React.useState<string>('1')

    React.useEffect(() => {
        (async () => {
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const { chainId } = await provider.getNetwork()
            setChainId(chainId.toString())
        })()
    }, [])

    if ((chainId_to_name as any)?.[chainId] !== undefined) 
        return (chainId_to_name as any)[chainId]
    return { name: "ETH", symbol: "ETH" }
}