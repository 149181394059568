export default class Maybe<A> {
    value: A | null;

    constructor(value: A) {
        this.value = value;
    }

    bind(fn: (arg0: A) => Maybe<A>): Maybe<A> {
        if (this.value === null) {
            return this
        }
        const value = fn(this.value);
        return value
    }

    unwrap(): A {
        if (this.value === null) {
            throw new Error('unwrap null')
        }
        return this.value
    }
}