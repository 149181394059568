import { ethers } from "ethers";

/**
 *  @date October 6th 2022
 *  @description get an ethers.js signer object 
 *  @author William Doyle
 */
const getSigner = async () => {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    await provider.send("eth_requestAccounts", []);
    return provider.getSigner()
}

export default getSigner