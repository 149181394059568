import { ethers } from 'ethers'
import getSigner from './getSigner';

export default async function callContractFunction(contract: ethers.Contract, functionName: string, callData: any, messageValue: number | ethers.BigNumber = 0) {
    const signer = await getSigner()
    const contractWithSigner = contract.connect(signer);

    const values = [...Object.values(callData)];

    try {
        console.log('calling contract function [funcationName, values, messageValue]', functionName, values, messageValue)
        console.log(`stub: ${contractWithSigner[functionName]}`)
        const _result = await contractWithSigner[functionName](...values, {value: messageValue})
        return _result
    }
    catch (e) {
        alert(JSON.stringify(e))
        console.log(e)
        console.log(`values `, values)
        return null
    }
}