import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CurrencyRecord, deserializeList, FullDetails } from '../../functions/getChallengeDetails'
import useCurrentAddress from '../../hooks/useCurrentAddress'
import { useNavigate } from 'react-router-dom'
import { setFocousTkn } from '../../redux/slices/typeIIChallengeDetailsSlice'
import useAllChallengeDetails from '../../hooks/useAllChallengeDetails'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SettingsIcon from '@material-ui/icons/Settings';
import "./Browse.css"
import OpenSeaLogo from "../../assets/opensea.svg"
import useNativeTokenName from '../../hooks/useNativeTokenName'

type ChallengeFilters = 'ALL' | 'MINE' | 'UNSOLVED'

const DataPaternToShow: { label: string, getter: (x: FullDetails) => string }[] = [
    {
        label: 'id',
        getter: (x: FullDetails) => x.id.toString(),
    },
    {
        label: 'bitsize',
        getter: (x: FullDetails) => x.bitsize_s,
    },
    {
        label: 'Public Key',
        getter: (x: FullDetails) => x.publicKey,
    },
    {
        label: 'Empty Signature',
        getter: (x: FullDetails) => x.emptySignature,
    },
    {
        label: 'Pubₓ',
        getter: (x: FullDetails) => x.smallPublicKey_s.x,
    },
    {
        label: 'Pubᵧ',
        getter: (x: FullDetails) => x.smallPublicKey_s.y,
    },
    {
        label: 'Small Public Key',
        getter: (x: FullDetails) => x.smallPublicKey_pari 
    },
    {
        label: 'Gₓ',
        getter: (x: FullDetails) => x.customParameters_s.Gx,
    },
    {
        label: 'Gᵧ',
        getter: (x: FullDetails) => x.customParameters_s.Gy,

    },
    {
        label: 'Prime',
        getter: (x: FullDetails) => x.customParameters_s.P,
    },
    {
        label: 'Solved By',
        getter: (x: FullDetails) => {
            if (x.nft_details === null)
                return 'TBD'
            return x.nft_details.solvedBy
        }
    },
    {
        label: 'Owned By',
        getter: (x: FullDetails) => {
            if (x.nft_details === null)
                return 'TBD'
            return x.nft_details.owner
        }
    },
    
]

export default function Browse() {
    const nativeTokenNameAndSymbol = useNativeTokenName()
    const [challengeFilter, setChallengeFilter] = React.useState<ChallengeFilters>('ALL')

    const [showRewardList, setShowRewardList] = React.useState<boolean>(false)
    const [focousedChallenge, setFocousedChallenge] = React.useState<FullDetails | null>(null)

    const details = useAllChallengeDetails()
    const navigate = useNavigate()
    const usrAddrs = useCurrentAddress()

    const dispatch = useDispatch()

    function ChallengeFiltersController() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {
                    ['ALL' as ChallengeFilters, 'MINE' as ChallengeFilters, 'UNSOLVED' as ChallengeFilters].map((option: ChallengeFilters, index: number) => {
                        return <div key={option} onClick={() => setChallengeFilter(option)} style={{
                            backgroundColor: challengeFilter === option ? 'darkgray' : 'gray',
                            margin: '30px',
                            padding: '10px',
                            flex: 1,
                            borderRadius: '10px',
                        }}>
                            {option}
                        </div>
                    })
                }
            </div>
        )
    }

    function RewardListOverlay() {
        const wrapperRef = React.useRef<HTMLDivElement | null>(null)
        useOnClickOutside(wrapperRef, () => setShowRewardList(false))

        if ((showRewardList === false) || (focousedChallenge === null))
            return null

        return <div style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: '100%',
            height: '100%',
            zIndex: 98,
            position: 'fixed',
            top: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }}>
            <div ref={wrapperRef} className="reward-list-overlay" style={{
                position: 'fixed',
                zIndex: 99,
                top: 200,
                flex: 1,
                backgroundColor: '#FFFFFF',
                    border: '1px solid black',
                    borderRadius: '10px',
            }}>
                <div style={{
                    margin: '10px',
                    padding: '10px',
                }}>
                    <h1>
                        Rewards And Prizes
                    </h1>
                    <h2>
                        To be automatically transfered to your wallet upon solving challenge {focousedChallenge?.id}
                    </h2>
                    <table id="rewards-table" border={1} style={{ borderColor: '#000000' }}>
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Currency Name</th>
                                <th>Currency Symbol</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* native token */}
                            <tr>
                                <td>NA</td>
                                <td>{nativeTokenNameAndSymbol.name}</td>
                                <td>{nativeTokenNameAndSymbol.symbol}</td>
                                <td>{focousedChallenge.sponsorship_details?.nativeTokenAmount_s}</td>
                            </tr>
                            {
                                (() => {
                                    function noDups(cr: CurrencyRecord[]): CurrencyRecord[] {
                                        const o: any = {}
                                        cr.forEach((x: CurrencyRecord) => o[x.currencyAddress] = x)
                                        return Object.values(o)
                                    }
                                    if (focousedChallenge.sponsorship_details?.currencyRecords === undefined)
                                        return null
                                    return noDups(focousedChallenge.sponsorship_details?.currencyRecords).map((cr: CurrencyRecord, index: number) => {
                                        return <tr key={index}>
                                            <td>{cr.currencyAddress}</td>
                                            <td>{cr.currencyName ?? "NA"}</td>
                                            <td>{cr.currencySymbol ?? "NA"}</td>
                                            <td>{cr.amount_s}</td>
                                        </tr>
                                    })
                                })()
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    return <div className="Browse" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    }}>
        <RewardListOverlay />

        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
        }}>
            <div style={{ flex: 1 }} />
            <ChallengeFiltersController />
            <div style={{ flex: 1 }} />
        </div>
        {
            details.map((challenge: FullDetails, index: number) => {

                if ((challengeFilter === 'MINE') && ((challenge.nft_details === null) || (challenge.nft_details.owner !== usrAddrs))) {
                    return null
                }

                if ((challengeFilter === 'UNSOLVED') && (challenge.is_solved)) {
                    return null
                }

                return <div key={index} className="Challenge-list-element" style={{
                    backgroundColor: index % 2 === 0 ? 'lightgray' : 'darkgray',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    width: '100%',
                    borderRadius: '10px',
                    marginTop: '10px',
                }}>
                    <div className='challenge-image-box'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}
                    >
                        <img
                            width={135}
                            // src={`https://firebasestorage.googleapis.com/v0/b/contracts-46e8f.appspot.com/o/ec_place_holder.jpg?alt=media`}
                            src={`https://firebasestorage.googleapis.com/v0/b/contracts-46e8f.appspot.com/o/secp256k1_matlab.png?alt=media&token=9f19804b-49a9-4b4b-91c6-a99aebfe9e68`}
                            alt="place holder of an elliptic curve" />
                    </div>
                    <div className='challenge-info-box'>
                        <table>
                            <tbody>
                                {
                                    DataPaternToShow.map((data, index) => {
                                        if (data.getter(challenge) === 'TBD') // skip invalid data
                                            return null
                                        return <tr key={index}>
                                            <td>{data.label}</td>
                                            <td>{data.getter(challenge)}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='challenge-interaction-box'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {
                            (() => {
                                if (challenge.is_solved) {
                                    return <a
                                        target="_blank"
                                        rel='noreferrer noopener'
                                        href={`https://testnets.opensea.io/assets/rinkeby/${challenge.contract_address}/${challenge.nft_details?.tkid_s}`}>
                                        {/* <OpenSeaLogo/>  */}
                                        <img src={OpenSeaLogo} alt="OpenSea" width={20} />
                                    </a>
                                }
                                return null
                            })()
                        }
                        {
                            (() => {

                                if (challenge.is_solved && (challenge.nft_details?.owner === usrAddrs)) {
                                    return <div onClick={() => {
                                        dispatch(setFocousTkn(parseInt(challenge.nft_details?.tkid_s as string)))
                                        navigate('ManageNFT')
                                    }}>
                                        {/* MANAGE */}
                                        <SettingsIcon />
                                    </div>
                                }
                                return null
                            })()
                        }
                        {
                            (() => {
                                if ((challenge.sponsorship_details !== null) && (challenge.is_solved === false)) {
                                    return <div onClick={() => {
                                        setFocousedChallenge(challenge)
                                        setShowRewardList(true)
                                    }}>
                                        {/* view rewards */}
                                        <EmojiEventsIcon />
                                    </div>
                                }
                                return null
                            })()
                        }
                        {
                            (() => {
                                if (challenge.is_solved) {
                                    if (challenge?.nft_details?.is_locked)
                                        return <LockIcon />
                                    return <LockOpenIcon />
                                }
                                return null
                            })()
                        }
                    </div>
                </div>
            })
        }
    </div>
}
