import React from "react";
import SponsorLogicFacet from '../../contractData/TypeIIChallenges/SponsorLogicFacet.json'
import { ethers } from 'ethers'
import callContractFunction from '../../functions/callContractFunction'
import address from "../../constants/ADDRESS";

/**
 *  TokenWhitelister
 *  Add a currency or NFT to the list of supported currencies
 *  William Doyle
 *  October 20th 2022
 */
export default function TokenWhiteLister() {
    const [addressToAdd, setAddressToAdd] = React.useState<string>('')
    const [isTokenContract, setIsTokenContract] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            if (addressToAdd === '')
                return
            const sponsorLogicFacet = new ethers.Contract(address, SponsorLogicFacet.abi)
            const status = await callContractFunction(sponsorLogicFacet, 'isTokenContract', [addressToAdd])
            console.log(`[Admin.tsx/CurrencyAdder/useEffect] `, status)
            setIsTokenContract(status)
        })()
    }, [addressToAdd])

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        padding: '10px',
        margin: '10px',
        borderRadius: '10px'
    }}>
        <h1>Add A Token To The List Of Supported Tokens</h1>
        <h2>Is A token contract: {isTokenContract ? 'yes' : 'no'}</h2>
        <div>
            <input type="text" placeholder="enter contract address" onChange={(e) => setAddressToAdd(e.target.value)} />
            {addressToAdd}
            <button onClick={async e => {
                const sponsorLogicFacet = new ethers.Contract(address, SponsorLogicFacet.abi)
                await callContractFunction(sponsorLogicFacet, 'whitelist_currency', [addressToAdd])
            }}>Add</button>
        </div>
    </div>
}