import React from "react"
import useCurrentAddress from "../../hooks/useCurrentAddress"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { FullDetails } from "../../functions/getChallengeDetails"
import useAllChallengeDetails from "../../hooks/useAllChallengeDetails"
import ClipLoader from "react-spinners/ClipLoader";
import solveChallenge from "./solveChallenge"
import spinnerMessages from "./spinnerMessages"
import SolverState from "./SolverState"
import "./Solve.css"
import LogEvent from "../../functions/LogEvent"

/**
 * @name Solve
 * @author William Doyle 
 * @returns A component that allows the user to solve a challenge or post a solution to a challenge 
 */
export default function Solve() {
    const [challengePosition, setChallengePosition] = React.useState<string>('');
    const [challengePrivateKey, setChallengePrivateKey] = React.useState<string>('');
    const [showAutoSolver, setShowAutoSolver] = React.useState<boolean>(false)
    const authUser = useCurrentAddress()
    const w = React.useRef<Worker | null>(null)

    React.useEffect(() => {
        if (w.current === null) {
            // w.current = new Worker(`worker.js`)
            w.current = new Worker(`typeTwoSolver.js`)
        }
    }, [])



    /**
     * @name AutoSolverOverlay
     * @author William Doyle
     * @description an overlay shown while interacting with the auto solver 
     */
    function AutoSolverOverlay() {
        const wrapperRef = React.useRef<HTMLDivElement | null>(null)
        useOnClickOutside(wrapperRef, () => setShowAutoSolver(false))
        const details = useAllChallengeDetails()

        const [focousedChallenge, setFocousedChallenge] = React.useState<FullDetails | null>(null)

        const [solverState, setSolverState] = React.useState<SolverState>('idle')
        const errors = React.useRef<string[]>([])

        /**
         * @name beginSolving
         * @author William Doyle
         * @description begins the process of automatically solving the challenge
         */
        function beginSolving() {
            setSolverState('solving')
        }

        /**
         * @author William Doyle
         * @description maintains focousedChallenge (the details of the challenge we want to solve) variable
         */
        React.useEffect(() => {
            if (challengePosition === '')
                return

            const iChallengePosition = parseInt(challengePosition)
            const challenge = details.find((d: FullDetails) => d.id === iChallengePosition)

            if (challenge !== undefined)
                setFocousedChallenge(challenge)
        }, [details])

        /**
         * @name IdleInterface
         * @author William Doyle
         * @description what to show when the auto solver is idle
         **/
        function IdleInterface() {
            return <div className="flexColumn">
                <h2>
                    Press The Button Below To Begin Solving
                </h2>
                <div className="flexRow">
                    <div className="flexOne" />
                    <div>
                        <button onClick={beginSolving} >
                            Begin Solving
                        </button>
                    </div>
                    <div className="flexOne" />
                </div>
            </div>
        }

        /**
         * @name SolvingInterface
         * @author William Doyle
         * @description what to show when the auto solver is solving... A spinner
         */
        function SolvingInterface() {
            const [messageIndex, setMessageIndex] = React.useState<number>(0)
            const isSetup = React.useRef<boolean>(false)

            React.useEffect(() => {
                if (isSetup.current === true)
                    return
                if (w.current !== null) {
                    // pass worker the information it needs to do its job
                    w.current.postMessage(JSON.stringify({
                        header: 'begin',
                        body: focousedChallenge
                    }))

                    // listen for messages from the worker
                    w.current.onmessage = async (e) => {
                        const { header, body } = JSON.parse(e.data)

                        if (typeof header !== 'string')
                            throw new Error(`header is not a string. It is a ${typeof header}, but it needs to be a string`)

                        if (typeof body !== 'object')
                            throw new Error(`body is not an object. It is a ${typeof body}, but it needs to be an object`)

                        console.log(`MASTER: received a message with header: ${header}`)

                        switch (header) {
                            case 'error':
                                LogEvent('Type2 Solver Error', { error: body.status }) // log the error to firebase
                                errors.current.push(body) // add the error to the errors array
                                console.log(`Solve::AutoSolverOverlay::SolvingInterface::SolvingInterface:: thread threw an error, imma kill it real quick`)
                                w.current?.terminate() // kill the worker who reported the error
                                setSolverState('failed') // move to the failed state 
                                break
                            case '__success__':
                                console.log(`Solve::AutoSolverOverlay::SolvingInterface worker reported success!`)
                                w.current?.terminate() // no longer needed
                                setSolverState('solved') // move to the success state
                                console.log(JSON.stringify(body, null, 2))

                                const empty = `0x0000000000000000000000000000000000000000000000000000000000000000`
                                const pri = () => JSON.parse(body.solution).join().split(',').join('')
                                const _pri = `${empty.substring(0, 66 - pri().length)}${pri()}`

                                console.log(`Solve::AutoSolverOverlay::SolvingInterface::SolvingInterface:: _pri: ${_pri}`)

                                if (focousedChallenge === null)
                                    throw new Error(`focousedChallenge is null`)

                                LogEvent('solution found', {
                                    challengeId: focousedChallenge.id,
                                    bitsize: focousedChallenge.bitsize_s,
                                    run_time: body.time
                                })
                                await solveChallenge(focousedChallenge?.id?.toString(), _pri, authUser)


                                
                                break
                            default:
                                throw new Error(`MASTER: received a message with an unknown header: ${header}`)
                        }
                    }

                }
            }, [])

            /**
             * @author William Doyle
             * @description changes the message shown in the spinner every period of time
             */
            React.useEffect(() => {
                const interval = setInterval(() => {
                    // const random: number = Math.floor(Math.random() * 100) + 1
                    // if (random > 10)
                    //     setSolverState('failed')
                    // if (random > 90)
                    //     setSolverState('solved')

                    setMessageIndex((prevMsgIdx) => (prevMsgIdx + 1) % spinnerMessages.length)
                }, 5555);

                return () => clearInterval(interval)
            }, []);

            const override: React.CSSProperties = {
                display: "block",
                margin: "0 auto",
                borderColor: "red",
            }

            return <div>
                <h2>
                    Solving Challenge {challengePosition}...
                </h2>
                <ClipLoader
                    color={'#FFFFFF'}
                    loading={true}
                    cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />

                <div className="spinner-message-display">
                    <p>
                        {spinnerMessages[messageIndex]}
                    </p>
                </div>
            </div>
        }

        /**
         * @name SolvedInterface
         * @author William Doyle
         * @description what to show when the auto solver has finished solving
         */
        function SolvedInterface() {
            return <div>
                <h2>
                    Ta-Da!
                </h2>
                <p>
                    Challenge {challengePosition} has been solved! Follow the steps in metamask to claim your reward!
                </p>
            </div>
        }

        /**
         * @name FailedInterface
         * @author William Doyle
         * @returns What to show upon failure
         */
        function FailedInterface() {
            return <div className="failed-interface">
                <h2>
                    Uh-Oh
                </h2>
                <h3>
                    The automatic solver failed. Here are the error messages:
                </h3>
                <div className="wide-text-wrap">
                    <p>
                        {JSON.stringify(errors.current, null, 2)}
                    </p>
                </div>
            </div>
        }

        if ((showAutoSolver === false) || (challengePosition === ''))
            return null

        return <div className="shadow">
            <div ref={wrapperRef} className="reward-list-overlay" >
                <h1>
                    In Browser Solver
                </h1>
                {
                    (() => {
                        if (focousedChallenge === null)
                            return <p>
                                Loading...
                            </p>

                        if (focousedChallenge.is_solved === true)
                            return <p>
                                {focousedChallenge?.nft_details?.solvedBy === authUser ? 'You' : focousedChallenge?.nft_details?.solvedBy} solved this challenge on {focousedChallenge?.nft_details?.ts_mint_human}!
                            </p>

                        switch (solverState) {
                            case 'idle':
                                return <IdleInterface />
                            case 'solving':
                                return <SolvingInterface />
                            case 'solved':
                                return <SolvedInterface />
                            case 'failed':
                                return <FailedInterface />
                            default:
                                return <p>
                                    Unknown state {solverState}
                                </p>
                        }
                    })()
                }
            </div>
        </div>
    }

    return <div className="back-wrap">
        <AutoSolverOverlay />
        <div className="Solve" >
            <h1>
                Welcome to Solve!
            </h1>
            <div className="text-wrap"               >
                <p>
                    This is where you can solve challenges! Either use the in browser solver or post a solution you've found via custom methods.
                </p>
            </div>
            <div className="solve-manually"                >
                <h2>
                    Manually Solve
                </h2>
                <p>
                    Enter the challenge position (ID) and the private key solution you've found.
                </p>
                <div>
                    <input
                        id="input-challenge-position"
                        value={challengePosition}
                        onChange={e => setChallengePosition(e.target.value)}
                    />
                    <label htmlFor="input-challenge-position">
                        Challenge Position
                    </label>
                </div>
                <div>
                    <input
                        id="input-challenge-private-key"
                        value={challengePrivateKey}
                        onChange={e => setChallengePrivateKey(e.target.value)}
                    />
                    <label htmlFor="input-challenge-private-key">
                        Challenge Private Key
                    </label>
                </div>
                <div>
                    <button onClick={(ev) => solveChallenge(challengePosition, challengePrivateKey, authUser)}>
                        Solve Challenge
                    </button>
                    {
                        (() => {
                            return <div> ( {challengePosition} , {challengePrivateKey}) </div>
                        })()
                    }
                </div>
            </div>

            <div className="solve-automatically"                >
                <h2>
                    Auto Solve
                </h2>
                <h3>
                    Comming Soon...
                </h3>
                <div>
                    <input
                        id="input-challenge-position"
                        value={challengePosition}
                        onChange={e => setChallengePosition(e.target.value)}
                    />
                    <label htmlFor="input-challenge-position">
                        Challenge Position
                    </label>
                    <div>
                        <button onClick={(ev) => setShowAutoSolver(!showAutoSolver)}>Begin Auto Solver</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}