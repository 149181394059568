import React from 'react';
// import './App.css';
import { useSelector, useDispatch } from 'react-redux'
import { setContractData } from './redux/slices/contractDataSlice'
import 'react-dropdown/style.css';
import { ethers } from 'ethers';
import QuickFill from './components/tools/QuickFill';
import Scoreboard from './components/Scoreboard/Scoreboard';
import ContractPanel from './components/ContractPanel';
import ListOfChallenges from './components/ListOfChallenges/ListOfChallenges';
import { Button } from '@material-ui/core';
import currentAuthUser from './functions/currentAuthUser';
import ChallengeModal from './components/ChallengeModal/ChallengeModal';
import SponsorModal from './components/SponsorModal/SponsorModal';
import SocialMediaLinks from './components/SocialMediaLinks';
import LargeChallengeModal from './components/LargeChallengeModal/LargeChallengeModal';
import TokenView from './components/TokenView/TokenView';
import _contractOptions from './_contractOptions';
import ContractData from './classes/ContractData';
import ContractDetailsRecord from './classes/ContractDetailsRecord';
import DirectToMarket from './components/DirectToMarket/DirectToMarket';
import PleaseInstallMetamask from './components/PleaseInstallMetamask/PleaseInstallMetamask';
import Title from './components/Title/Title';
import ensureHasMetamask from './functions/ensureHasMetamask';
import Warning from './components/Warning/Warning';
import Maybe from './components/Maybe/Maybe';
import ProcessSolution from './components/ProcessSolution/ProcessSolution';
import LogEvent from './functions/LogEvent';
import useMaintainChain from './hooks/useMaintainChain';
import Dev from './classes/Dev';
import BlockSearcher from './components/BlockSearcher/BlockSearcher';

function App() {

  (window as any).ethereum.on('chainChanged', () => {
    document.location.reload()
  })

  const dispatch = useDispatch()
  const cd = useSelector((state: any) => state.contractData.contractData)

  const [showTools, setShowTools] = React.useState<Boolean>(false)
  const [userAddress, setUserAddress] = React.useState<string>('')
  const [hasMetamask, setHasMetamask] = React.useState<Boolean>(true)
  const [showContractPanel, setShowContractPanel] = React.useState<Boolean>(false)

  const hasLoaded = React.useRef(false)
  React.useEffect(() => {
    if (!hasLoaded.current)
      LogEvent('app_load')
    hasLoaded.current = true
  }, [])

  /* 
    source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
    August 8th 2022
    William Doyle
  */
  const [showButton, setShowButton] = React.useState<boolean>(false); // back to top button

  const queryParams = React.useMemo(() => new URLSearchParams(window.location.search), []);

  // useMaintainChain('0x4')
  useMaintainChain(parseInt(queryParams.get('chainid') ?? '137').toString(16))

  React.useEffect(() => {
    /* 
      source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
      August 8th 2022
      William Doyle
    */
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300)
        setShowButton(true)
      else
        setShowButton(false)
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    })
  }

  React.useEffect(() => {
    ensureHasMetamask(() => setHasMetamask(false));
    (async () => setUserAddress(await currentAuthUser()))();

    const co_vals: ContractData[] = _contractOptions.map((c: ContractDetailsRecord) => c.value)
    // const target_index: number = co_vals.findIndex((c: ContractData) => c.blockchain?.chainid.toString() === queryParams.get('chainid') ?? '-1')
    const target_index: number = co_vals.findIndex((c: ContractData) => {
      const chainId : string | null = queryParams.get('chainid')
      // console.log(`App.tsx::React.useEffect chainid is --> `, chainId)

      // if no chainid is specified, redirect to __current_url__?chainid=137
      if (chainId === null) {
        const new_url = `${window.location.href}?chainid=137`
        window.location.href = new_url

      }

      return c.blockchain?.chainid.toString() === chainId 
    })

    console.log('target_index', target_index)
    // if (target_index === -1) {
    //   Dev.log(`STUB: Need user to specify chainid`)
    //   setSpecifiesChainId(false)
    //   return
    // }

    const contractDataToUse: ContractData = co_vals[target_index]
    dispatch(setContractData(contractDataToUse));

    // (async () => {
    //   await (window as any).ethereum.request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{ chainId: queryParams.get('chainid') }], // chainId must be in hexadecimal numbers
    //   });
    //   setIsConnectedToCorrectChain(true)
    // })()

    // ensureConnectedToCorrectChain(contractDataToUse, () => setIsConnectedToCorrectChain(false))
  }, [dispatch, queryParams])

  // if (specifiesChainId === false)
  //   return <ListOfSupportedChains />

  // IF NO METAMASK IS INSTALLED, SHOW A MESSAGE
  if (!hasMetamask)
    <PleaseInstallMetamask />

  // if (!isConnectedToCorrectChain)
  //   return <PleaseConnectToCorrectBlockchain correct_chain_id={cd?.blockchain?.chainid ?? '-1'} />

  return (
    <div className="App">
      <ProcessSolution />
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={8}
        color='193, 11, 111'
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
      /> */}

      <header className="App-header">

        <Maybe IF={cd?.blockchain?.isTestNet ?? false}
          THEN={<Warning />}
          OTHERWISE={<></>} />

        <div className="fancy-title-wrap">
          <div className="wide-spacer" />
          <div className="fancy-title">
            <Title text='Proof-of-quantum™' level={1} />
          </div>
          <div className="wide-spacer" />
        </div>
        <SocialMediaLinks position='top' />
      </header>
      <div className="Main">
        {/* <BlockSearcher /> */}
        <LargeChallengeModal />
        <ChallengeModal />
        <SponsorModal />
        <Title text='Scoreboard' level={2} />
        <Title text='Progress of Classical and Quantum Computers At Breaking Secp256k1 Elliptic Curve Cryptography' level={3} />
        <Scoreboard />
        {/* <button onClick={() => LogEvent('btn_pressed')}>Log Event</button> */}
        <div className="challenges-section">
          <Title text='Challenges' level={2} />
          <ListOfChallenges />
          {
            (() => {
              if (userAddress === '0x94D4Da7bDe814ae3B7a3D25A108391018e1e495E')
                return <Button variant="outlined" onClick={() => setShowTools(!showTools)}>
                  <div className="Button-Label">
                    Show/Hide Tools
                  </div>
                </Button>
            })()
          }
        </div>
        {
          (() => {
            if (showTools)
              return <div className="Additional-Tools">
                <QuickFill />
              </div>
          })()
        }
        <div>
          <button className="btn-type-2" onClick={() => setShowContractPanel(!showContractPanel)}>
            Show/Hide Contract Panel
          </button>
        </div>
        {
          (() => {
            if ((showContractPanel) && (cd))
              return <ContractPanel contract={new ethers.Contract(cd.address, cd.abi)} />
          })()
        }
      </div>
      <DirectToMarket />
      <TokenView address={userAddress} />
      <div className="footer">
        <div className='footer-element'>
          <SocialMediaLinks position='bottom' bottom={true} />
        </div>
      </div >
      {
        /* 
          source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
          August 8th 2022
          William Doyle
        */
        // showButton && (
        // <button onClick={scrollToTop} className="back-to-top">
        //   &#8679;
        // </button>
        // )
      }
      <Maybe
        IF={showButton}
        THEN={
          <button onClick={scrollToTop} className="back-to-top">
            &#8679;
          </button>
        }
        OTHERWISE={<></>}
      />
    </div >
  );
}

export default App;


// TOP        --> Title + Link Bar
// CONTENT 1  --> Scoreboard
// CONTENT 2  --> List of Challenges
// CONTENT 3  --> Direct To Market
// BOTTOM     --> Footer (Link Bar Again)