import React from 'react'
import useAllChallengeDetails from '../../hooks/useAllChallengeDetails'
import { FullDetails } from '../../functions/getChallengeDetails'
import { ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts'


/**
 * @date September 23rd 2022
 * @returns 
 */
export default function SolutionPlot() {
    const details = useAllChallengeDetails()
    const [solutionDetails, setSolutionDetails] = React.useState([] as FullDetails[])



    // when `details` changes, so might `solutionDetails` 
    React.useEffect(() => setSolutionDetails(details.filter((x: FullDetails) => x.is_solved)), [details])

    return (
        <div style={{
            // backgroundColor: 'lightcoral',
            backgroundColor: 'white',
            flex: 1,
        }}>
            <ResponsiveContainer width="100%" height={600}>
                <ScatterChart data={solutionDetails}>
                    {
                        (() => {
                            const dates: number[] = solutionDetails.map((entry: FullDetails) => entry.nft_details?.ts_mint ?? 0)
                            const minDate = Math.min(...dates)
                            const maxDate = Math.max(...dates)

                            return <XAxis
                                scale="time"
                                type="number"
                                dataKey="nft_details.ts_mint"
                                domain={[minDate, maxDate]}
                                tickFormatter={(ts: number) => new Date(ts * 1000).toDateString()}
                            />
                        })()
                    }
                    <YAxis
                        domain={[0, 256]}
                        // ticks={[0, 32, 64, 128, 192, 256]}
                        ticks={Array.from({length: 9}, (_, i) => i * 32)}
                    ></YAxis>

                    <Scatter
                        name="Bitsizes"
                        dataKey="bitsize_n"
                    >

                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    )
}