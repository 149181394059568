import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/Layout';
import TypeIIChallenges from './pages/TypeIIChallenges';
import NotFound from './pages/404';
import ManageNFT from './pages/ManageNFT';
import Admin from './pages/Admin';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <App /> */}
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<App />} />
            <Route path="TypeII" element={<TypeIIChallenges/>} />
            <Route path="TypeII/ManageNFT" element={<ManageNFT/>} />
            <Route path="Admin" element={<Admin/>} />
            <Route path="*" element={<NotFound/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
