import React from 'react'
import { useSelector } from 'react-redux'
import { FullDetails } from '../functions/getChallengeDetails'
import { ethers } from 'ethers'
import LockWithLamport from "../contractData/TypeIIChallenges/LockWithLamport.json"
import NFTLogicFacet from "../contractData/TypeIIChallenges/NFTLogicFacet.json"
import KeyTracker from '../offchain/KeyTracker'
import callContractFunction from '../functions/callContractFunction'
import FileInput from '../components/FileInput/FileInput'
import { hash, hash_b, pubFromPri, sign_hash, verify_signed_hash } from '../offchain/functions'
import useAllChallengeDetails from '../hooks/useAllChallengeDetails'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import "./ManageNFT.css"
import useCurrentAddress from '../hooks/useCurrentAddress'
import getSigner from '../functions/getSigner'

/**
 * lock
 * @date September 16th 2022
 */
async function lock(details: FullDetails) {
    const lockWithLamport = new ethers.Contract(details.contract_address, LockWithLamport.abi)
    const kt = new KeyTracker('testnet')
    const current = kt.currentKeyPair()
    kt.save();
    await callContractFunction(lockWithLamport, 'lock', [details?.nft_details?.tkid_s, KeyTracker.pkhFromPublicKey(current.pub)])
}

/**
 * transfer
 * october 17th 2022
 * transfer a token to a new owner
 */
async function transfer(details: FullDetails, transferTo: string, authUser: string) {
    const nftLogicFacet = new ethers.Contract(details.contract_address, NFTLogicFacet.abi)
    await nftLogicFacet
        .connect(await getSigner())
        .transferFrom(authUser, transferTo, details?.nft_details?.tkid_s)
}

export default function ManageNFT() {
    const focousTkn: number = useSelector((state: any) => state.typeIIChallengeDetails.focousTkn)
    const [focousDetails, setFocousDetails] = React.useState<FullDetails | null>(null)
    const details = useAllChallengeDetails()
    const authUser = useCurrentAddress()

    // get the details of the focous challenge (from redux)
    React.useEffect(() => {
        const temp: FullDetails | undefined = details.find((x: FullDetails) => {
            if (x.nft_details === null)
                return false
            return x.nft_details.tkid_s === focousTkn.toString()
        })
        if (temp === undefined)
            return
        setFocousDetails(temp)
    }, [focousTkn, details])


    // lock and unlock with a lamport single use public key crypto system
    function LamportManagement() {

        // help the user lock their NFT with a lamport public key
        function Locker() {
            return <div className="wizard">
                <h4>Lock This Asset</h4>
                <button onClick={() => lock(focousDetails as FullDetails)} >
                    Press To Lock
                </button>
                <br />
                <strong>
                    Important: Save the generated file! If you don't you will never be able to transfer this asset.
                </strong>
            </div>
        }

        // help the user unlock their NFT with a lamport signature
        function Unlocker() {
            const [keyFiles, setKeyFiles] = React.useState<File[] | null>(null)
            const [haveValidKeyFile, setHaveValidKeyFile] = React.useState<boolean>(false)
            const [keytracker, setKeytracker] = React.useState<KeyTracker | null>(null)

            async function unlock() {
                if ((haveValidKeyFile === false) || (focousDetails === null) || (keytracker === null))
                    return

                const current = keytracker.currentKeyPair()
                const lockWithLamport = new ethers.Contract(focousDetails.contract_address, LockWithLamport.abi)

                const packed = ethers.utils.solidityPack(['uint256'], [focousDetails?.nft_details?.tkid_s])
                const h = hash_b(packed)
                const sig = sign_hash(h, current.pri)

                const is_valid_sig = verify_signed_hash(h, sig, current.pub)
                if (is_valid_sig === false) {
                    throw new Error(`[ManageNFT.tsx/LamportManagement/Unlocker/unlock] somehow we've made an invalid signature`);
                }

                const result = await callContractFunction(lockWithLamport, 'unlock', [focousDetails?.nft_details?.tkid_s, current.pub, sig.map((s: string) => `0x${s}`)])
                console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/unlock] result --> `, result)
            }

            function handleFileChange(files: File[]) {
                console.log(files)
                setKeyFiles(files)
            }

            // when the key file is changed, 
            React.useEffect(() => {
                console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/useEffect] keyFiles have changed`);
                (async () => {
                    if (keyFiles === null) {
                        return
                    }

                    // 1. get the file
                    const file: File = keyFiles[0]

                    // 2. read the file
                    const body = await file.text()

                    const _keytracker = KeyTracker.loadFromString(body)

                    // 3. make sure the keytracker's public key matches its own private key
                    const current = _keytracker.currentKeyPair()
                    const local_expected_pub = current.pub

                    const recalc_pub = pubFromPri(current.pri.map(p => [`0x${p[0]}`, `0x${p[1]}`]))

                    console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/useEffect] local_expected_pub: ${local_expected_pub}`)
                    console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/useEffect] recalc_pub: ${recalc_pub}`)

                    if (hash(local_expected_pub.toString()) !== hash(recalc_pub.toString())) {
                        setHaveValidKeyFile(false)
                        console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/useEffect] key file is invalid because it is not self consistent`)
                        return
                    }

                    // 4. compaire to on chain public key
                    const oc_pub = focousDetails?.nft_details?.lamportPublicKeyHash
                    if ((oc_pub === null) || (oc_pub === undefined)) {
                        return
                    }

                    // if (hash(local_expected_pub.toString()) !== hash(oc_pub.toString())) {
                    if (KeyTracker.pkhFromPublicKey(local_expected_pub) !== oc_pub) {
                        setHaveValidKeyFile(false)
                        console.log(`[ManageNFT.tsx/LamportManagement/Unlocker/useEffect] key file is invalid because it does not match the on chain public key`)
                        return
                    }

                    // 5. if we get here, the key file is valid
                    setKeytracker(_keytracker)
                    setHaveValidKeyFile(true)
                })()

            }, [keyFiles])

            return <div className='wizard'>
                <h4>Token Locked For Protecton Against Quantum Attack! Select your private key file to unlock it</h4>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    alignItems: 'space-around',
                    border: '1px solid black',
                    borderRadius: '10px',
                }}>
                    <div
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            margin: '10px',
                        }}
                        className='unlock-btn'
                        onClick={unlock}>Unlock</div>
                    <FileInput value={keyFiles ?? []} onChange={handleFileChange} label="Select A File" />
                    <div style={{
                        padding: '10px',

                        margin: '10px',
                    }}>
                        {
                            (() => {
                                if (haveValidKeyFile)
                                    return <div>✅</div>
                                return <div>❌</div>
                            })()
                        }
                    </div>
                </div>
            </div>
        }

        return <div className={'ManageNFT-Option'} >
            {
                (() => {
                    if (focousDetails?.nft_details?.is_locked)
                        return <Unlocker />
                    return <Locker />
                })()
            }

        </div>
    }

    function TransferInterface() {
        const [to, setTo] = React.useState<string>('')

        return <div className='wizard'>
            <h4>Transfer Token #{`${focousDetails?.nft_details?.tkid_s}`}</h4>
            <div>
                <button onClick={() => transfer(focousDetails as FullDetails, to, authUser)}>Transfer</button>
                <input type='text' placeholder='Address' value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
        </div>
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                fontFamily: 'monospace',
                color: 'black',
                textShadow: '0px 0px 0px black',
            }}>

            <h2>Manage Token #{focousTkn}</h2>
            <div className='manage-nft-actions' >
                <h3>Actions</h3>
                <div style={{
                    border: '1px solid black',
                    borderRadius: '5px',
                    margin: '10px',
                    padding: '10px',
                }}
                >
                    <LamportManagement />
                    <TransferInterface />
                </div>

            </div>

            <div className='manage-nft-data'>
                <h3>Token Information And Status</h3>
                <div style={{
                    border: '1px solid black',
                    borderRadius: '5px',
                    margin: '10px',
                    padding: '10px',
                }}>
                    <div className="outer-grid">
                        <div className="grid-row">
                            <div className="grid-element"><b>Token Id</b></div>
                            <div className="grid-element">{focousDetails?.nft_details?.tkid_s}</div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>Owner</b></div>
                            <div className="grid-element">{focousDetails?.nft_details?.owner}</div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>Solved By</b></div>
                            <div className="grid-element">{
                                (() => {
                                    if (focousDetails?.nft_details?.solvedBy === focousDetails?.nft_details?.owner)
                                        return 'current owner'
                                    return focousDetails?.nft_details?.solvedBy
                                })()
                            }</div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>Timestamp</b></div>
                            <div className="grid-element">{focousDetails?.nft_details?.ts_mint_human}</div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>Block Explorer</b></div>
                            <div className="grid-element">
                                <a href={focousDetails?.nft_details?.block_explorer} target="_blank" rel="noopener noreferrer">Etherscan</a>
                            </div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>bitsize</b></div>
                            <div className="grid-element">{focousDetails?.bitsize_s}</div>
                        </div>
                        <div className="grid-row">
                            <div className="grid-element"><b>publicKey</b></div>
                            <div className="grid-element">{focousDetails?.publicKey}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>empty signature</b></div>
                            <div className="grid-element">{focousDetails?.emptySignature}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Pubₓ</b></div>
                            <div className="grid-element">{focousDetails?.smallPublicKey_s.x}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Pubᵧ</b></div>
                            <div className="grid-element">{focousDetails?.smallPublicKey_s.y}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Gₓ</b></div>
                            <div className="grid-element">{focousDetails?.customParameters_s.Gx}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Gᵧ</b></div>
                            <div className="grid-element">{focousDetails?.customParameters_s.Gy}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Prime</b></div>
                            <div className="grid-element">{focousDetails?.customParameters_s.P}</div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-element"><b>Quantum Lock Status</b></div>
                            <div className="grid-element">{focousDetails?.nft_details?.is_locked ? <><LockIcon /> {`PKH:${focousDetails?.nft_details?.lamportPublicKeyHash}`}</> : <LockOpenIcon />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

