import React from "react";
import currentAuthUser from "../functions/currentAuthUser";

export default function useCurrentAddress() {
    const [address, setAddress] = React.useState<string>(`0x${'0'.repeat(64)}`);

    React.useEffect(() => {
        (async () => {
            const user = await currentAuthUser();
            setAddress(user)
        })()
    }, [])

    return address;
}