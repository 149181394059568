import { ethers } from 'ethers';

export default async function currentAuthUser() {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
    return signer.getAddress()
}

/**
 *  August 16th 2022
 *  William Doyle
 *  currentAuthUserPublicKey
 *  @description returns the public key of the current user 
 */
export async function currentAuthUserPublicKey() {
    const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' })
    const account = accounts[0]

    const publicKey = await (window as any).ethereum.request({
        method: 'eth_getEncryptionPublicKey',
        params: [account],
    })
    console.log(`publicKey --> `, publicKey)
    return publicKey
}