import { createSlice } from '@reduxjs/toolkit'

const typeIIChallengeDetailsSlice = createSlice({
    name: 'typeIIChallengeDetailsSlice',
    initialState: {
        challenges: `[]`,
        focousTkn: 0,
    },
    reducers: {
        setChallenges: (state: any, action: any) => {
            state.challenges = action.payload
        },
        setFocousTkn: (state: any, action: any) => {
            if (action.payload >= state.challenges.length)
                throw new Error('focousTkn out of range')
            state.focousTkn = action.payload
        }
    }
})
export default typeIIChallengeDetailsSlice
export const { setChallenges, setFocousTkn } = typeIIChallengeDetailsSlice.actions
