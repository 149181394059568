import { ethers } from "ethers"
import getSigner from "../../functions/getSigner"
import Currency from "./Currency"
import Dollar from "../../contractData/Dollar/Dollar.json"
import SponsorLogicFacet from "../../contractData/TypeIIChallenges/SponsorLogicFacet.json"

/**
 *  @description a curried function to return a function that will execute a sponsorship 
 *  @author William Doyle
 *  @date October 7th 2022
 */
const _executeSponsorship = (currency : Currency | null, challengeIndexToSponsor : string, amountToGive : string, sponsorMessage : string, diamondAddress : string) =>  async function executeSponsorship() {
    if (currency === null)
        return

    const signer = await getSigner()
    const sponsorLogicFacet = new ethers.Contract(diamondAddress, SponsorLogicFacet.abi, signer)

    if (currency?.contract === null) {
        await sponsorLogicFacet.sponsor_native(challengeIndexToSponsor, ethers.utils.parseEther(amountToGive), sponsorMessage, { gasLimit: 1000000, value: sponsorMessage.length === 0 ? ethers.utils.parseEther(amountToGive) : ethers.utils.parseEther(amountToGive).add(ethers.utils.parseEther('1')) })
        return
    }

    await (new ethers.Contract(currency.address, Dollar.abi, signer)).approve(diamondAddress, amountToGive)
    await sponsorLogicFacet.sponsor_erc20(challengeIndexToSponsor, amountToGive, currency.address, sponsorMessage, { gasLimit: 1000000, value: sponsorMessage.length === 0 ? 0 : ethers.utils.parseEther('1') })
}

export default _executeSponsorship