import React from "react";
import useCurrentAddress from "../../hooks/useCurrentAddress";
import { ethers } from "ethers";
import Dollar from "../../contractData/Dollar/Dollar.json"
import callContractFunction from "../../functions/callContractFunction"
import getNativeBalance from "../../functions/getNativeBalance";
import SponsorLogicFacet from "../../contractData/TypeIIChallenges/SponsorLogicFacet.json"
import UniqueAsset from "../../contractData/UniqueAsset/UniqueAsset.json"
import addresses from "../../contractData/TypeIIChallenges/addresses.json"
import Dropdown from 'react-dropdown';
import Currency from "./Currency";
import _executeSponsorship from "./_executeSponsorship";
import _executeNftSponsorship from "./_executeNftSponsorship";
import Maybe from "../../classes/Maybe";
import "./Sponsor.css"
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// const address = addresses['sepolia']
import useNativeTokenName from "../../hooks/useNativeTokenName";
import address from "../../constants/ADDRESS";

const initial: Currency[] = [
    // {
    //     name: 'Native Token',
    //     symbol: 'ETH',
    //     address: '0x0000000000000000000000000000000000000000',
    //     contract: null,
    // }
]

export default function Sponsor() {
    const authUser = useCurrentAddress();
    const nativeTokenNameDetails = useNativeTokenName();

    const [userHoldings, setUserHoldings] = React.useState<string[]>([] as string[])

    const [currency, setCurrency] = React.useState<Currency | null>(null)

    const [approved_currencies, setApprovedCurrencies] = React.useState<Currency[]>(initial)
    const [approved_nfts, setApprovedNFTs] = React.useState<Currency[]>([])

    const [selectedPrizeTokenContract, setSelectedPrizeTokenContract] = React.useState<ethers.Contract | null>(null)
    const [prizeTokenId, setPrizeTokenId] = React.useState<string>('0')
    const [numOwned, setNumOwned] = React.useState<string>('')
    const [amountToGive, setAmountToGive] = React.useState<string>('0')
    const [challengeIndexToSponsor, setChallengeIndexToSponsor] = React.useState<string>('0')
    const [sponsorMessage, setSponsorMessage] = React.useState<string>("")

    const [userDidCheckBox, setUserDidCheckBox] = React.useState<boolean>(false)
    const [ownsSelectedToken, setOwnsSelectedToken] = React.useState<boolean>(false)

    const [executeSponsorship, setExecuteSponsorship] = React.useState<() => Promise<void>>(async () => { })
    React.useEffect(() => setExecuteSponsorship(() => _executeSponsorship(currency, challengeIndexToSponsor, amountToGive, sponsorMessage, address)), [currency, challengeIndexToSponsor, amountToGive, sponsorMessage])

    const [executeNftSponsorship, setExecuteNftSponsorship] = React.useState<() => Promise<void>>(async () => { })
    React.useEffect(() => {
        if (selectedPrizeTokenContract === null)
            return
        // setExecuteNftSponsorship(_executeNftSponsorship(selectedPrizeTokenContract)(prizeTokenId)(challengeIndexToSponsor)(sponsorMessage)(address))
        setExecuteNftSponsorship(() => _executeNftSponsorship(selectedPrizeTokenContract)(prizeTokenId)(challengeIndexToSponsor)(sponsorMessage)(address))
    }, [selectedPrizeTokenContract, prizeTokenId, challengeIndexToSponsor, sponsorMessage])

    React.useEffect(() => {
        (async () => {
            // 1. get white listed currencies from contract
            const contract: ethers.Contract = new ethers.Contract(address, SponsorLogicFacet.abi)
            const raw = await callContractFunction(contract, 'get_whitelisted_currencies', [])
            console.log('raw', raw)

            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            // 2. convert to Currency type
            const _currencies: (Currency | null)[] = await Promise.all(raw.map(async (currency: string) => {
                const currencyContract = new ethers.Contract(currency, Dollar.abi, provider)
                try {
                    const currencyName = (await currencyContract.name()).toString()
                    const currencySymbol = (await currencyContract.symbol()).toString()

                    return {
                        address: currency,
                        name: currencyName,
                        symbol: currencySymbol,
                        contract: currencyContract
                    }
                }
                catch (e) {
                    return null
                }
            }))

            const currencies = _currencies.filter((c: Currency | null) => c !== null) as Currency[]

            // 3. set state
            // setApprovedCurrencies([...initial, ...currencies])
            setApprovedCurrencies([{
                name: nativeTokenNameDetails.name,
                symbol: nativeTokenNameDetails.symbol,
                address: '0x0000000000000000000000000000000000000000',
                contract: null,
            }, ...currencies])
        })()
    }, [nativeTokenNameDetails])

    // when approved_currencies changes, filter to update approved_nfts
    React.useEffect(() => {
        (async () => {
            console.log('approved_currencies', approved_currencies)
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const nftContracts: Currency[] = []
            for (let i = 0; i < approved_currencies.length; i++) {
                const currency = approved_currencies[i]
                const contractAddress = currency.address
                const contract = new ethers.Contract(contractAddress, UniqueAsset.abi, provider)
                try {
                    const isNFT = await contract.supportsInterface('0x80ac58cd')
                    if (isNFT === true) {
                        currency.contract = contract
                        nftContracts.push(currency)
                    }
                } catch (e) {
                    continue;
                }
            }

            setApprovedNFTs(nftContracts)
        })()
    }, [approved_currencies])

    // when selectedPrizeTokenContract changes... update count of owned tokens
    React.useEffect(() => {
        (async () => {
            if (selectedPrizeTokenContract === null)
                return;
            const balance = await selectedPrizeTokenContract.balanceOf(authUser)
            console.log('balance --> ', balance.toString())

            setNumOwned(balance.toString())

        })()
    }, [selectedPrizeTokenContract, authUser])

    // when the prize address or token id changes, check if user owns the token
    React.useEffect(() => {
        (async () => {
            if (selectedPrizeTokenContract === null)
                return;
            const actualOwner = await selectedPrizeTokenContract.ownerOf(prizeTokenId)
            console.log('actualOwner --> ', actualOwner)

            setOwnsSelectedToken(actualOwner === authUser)
        })()
    }, [selectedPrizeTokenContract, prizeTokenId, authUser])

    React.useEffect(() => {
        if (authUser === `0x0000000000000000000000000000000000000000000000000000000000000000`) {
            return
        }
        (async () => {
            const tmp_holdings = await Promise.all(approved_currencies.map(async curr => {
                // if (curr.name === 'Native Token')
                if (curr.name === nativeTokenNameDetails.name)
                    return await getNativeBalance(authUser)
                if (curr.contract === null)
                    return 'loading'
                return await callContractFunction(curr.contract, 'balanceOf', [authUser])
            }))
            setUserHoldings(tmp_holdings.filter(el => el !== null).map(x => x.toString()))
        })()
    }, [authUser, approved_currencies, nativeTokenNameDetails])

    return (
        <div className="Sponsor">
            <div className="currency-sponsor-input" style={{
                backgroundColor: '#CCCCCC',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px',
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <h2>Sponsor With Currency </h2>
                <div style={{
                    width: '50%',
                    whiteSpace: 'pre-wrap',
                    margin: 'auto',
                }}>
                    <p>Select an approved currency from the list below to sponsor a challenge. Don't see a currency you want to sponsor with? Send us a message and we'll consider adding it!</p>
                </div>
                <div className="sponsor-input">
                    <label htmlFor="sponsor-amount-input">
                        Sponsorship Amount
                    </label>
                    <input
                        id="sponsor-amount-input"
                        type="number"
                        value={amountToGive}
                        onChange={(e: any) => setAmountToGive(e.target.value)} />
                </div>
                <div className="sponsor-input">
                    <label htmlFor="challenge-index-to-sponsor-input">
                        Challenge To Sponsor
                    </label>
                    <input
                        id="challenge-index-to-sponsor-input"
                        type="number"
                        value={challengeIndexToSponsor}
                        onChange={(e: any) => setChallengeIndexToSponsor(e.target.value)} />
                </div>
                <div className="sponsor-input">
                    <label htmlFor="sponsor-message-input">
                        sponsor message
                    </label>
                    <input
                        id="sponsor-message-input"
                        type="text"
                        value={sponsorMessage}
                        onChange={(e: any) => setSponsorMessage(e.target.value)} />
                    {
                        (() => {
                            if (sponsorMessage.length > 0)
                                return <p>NOTICE: adding a message to a sponsorship will incur a fee of 1 {nativeTokenNameDetails.symbol} </p>
                            return null
                        })()
                    }
                </div>

                <br />

                <table id="currency-table">
                    <thead>
                        <tr>
                            {/* <th></th> */}
                            <th>Currency</th>
                            <th>Symbol</th>
                            <th>Address</th>
                            <th>Your Holdings</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (() => {
                                const removeNFTs = (currencies: Currency[]) => new Maybe<Currency[]>(approved_currencies.filter(apcur => (approved_nfts.map((c: Currency) => c.address).includes(apcur.address) === false)))
                                const tokenContracts: Maybe<Currency[]> = new Maybe<Currency[]>(approved_currencies)
                                    .bind(removeNFTs)
                                return tokenContracts.unwrap().map((x: Currency, i: number) => {
                                    return (
                                        <tr
                                            onClick={() => setCurrency(x)}
                                            key={i}>
                                            {/* <td><button onClick={() => setCurrency(x)} >SELECT</button></td> */}
                                            <td>{x.name}</td>
                                            <td>{x.symbol}</td>
                                            <td>{x.address}</td>
                                            {
                                                (() => {
                                                    if (authUser === null)
                                                        return <td>NA</td>
                                                    if (userHoldings.length === 0)
                                                        return <td>NA</td>
                                                    return <td> {userHoldings[i]}</td>
                                                })()
                                            }
                                            <td>
                                                {
                                                    (() => {

                                                        if (currency?.address === x.address)
                                                            return <StarIcon />
                                                        return <StarBorderIcon />
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            })()
                        }
                    </tbody>
                </table>
                <div>

                    <input
                        id="sponsor-understands-checkbox"
                        type="checkbox"
                        value={userDidCheckBox.toString()}
                        onChange={(e: any) => setUserDidCheckBox(!userDidCheckBox)} />
                    <label htmlFor="sponsor-understands-checkbox">
                        I read the thing
                    </label>
                </div>
                <br />
                <button
                    style={{
                        width: '100%',
                    }}
                    disabled={(currency === null || amountToGive === '0' || challengeIndexToSponsor === null || userDidCheckBox === false)}
                    onClick={executeSponsorship}
                >
                    Send Sponsorship
                </button>
            </div>

            <div className="nft-sponsorship-input" style={{
                backgroundColor: '#EEEEEE',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px',
            }}>

                <h2>Sponsor With A Unique Asset</h2>
                <div style={{
                    margin: 'auto',
                    width: '50%',
                    whiteSpace: 'pre-wrap',
                }}>
                    <p>Select an approved NFT contract and a token id to sponsor a challenge with your NFT! We're always looking to add to this list, so if you have a token contract you want us to support, let us know!</p>
                </div>
                <Dropdown
                    options={approved_nfts.map((x: Currency, i: number) => ({ value: x.address, label: `${x.name} (${x.address})` }))}
                    onChange={(a: any) => {
                        const c: ethers.Contract | null | undefined = (approved_nfts).find(x => x.address === a.value)?.contract
                        if ((c === undefined) || (c === null)) {
                            return
                        }
                        setSelectedPrizeTokenContract(c)
                    }}
                />
                {
                    selectedPrizeTokenContract ? `(address: ${selectedPrizeTokenContract.address}, balance: ${numOwned})` : 'no contract selected'
                }
                <br />

                <div style={{
                    backgroundColor: ownsSelectedToken ? 'green' : 'red',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    textAlign: 'center',
                }} >
                    {(() => {
                        if (ownsSelectedToken)
                            return `You own this token`
                        return `You do not own this token`
                    })()}
                </div>

                <div className="sponsor-input">
                    <label htmlFor="sponsor-tokenid-input">
                        Token Id To Post As Sponsorship
                    </label>
                    <input
                        id="sponsor-tokenid-input"
                        type="number"
                        value={prizeTokenId}
                        onChange={(e: any) => setPrizeTokenId(e.target.value)} />
                </div>
                <div className="sponsor-input">
                    <label htmlFor="challenge-index-to-sponsor-input">
                        Challenge To Sponsor
                    </label>
                    <input
                        id="challenge-index-to-sponsor-input"
                        type="number"
                        value={challengeIndexToSponsor}
                        onChange={(e: any) => setChallengeIndexToSponsor(e.target.value)} />
                </div>
                <div>
                    <input
                        id="sponsor-understands-checkbox-2"
                        type="checkbox"
                        value={userDidCheckBox.toString()}
                        onChange={(e: any) => setUserDidCheckBox(!userDidCheckBox)} />
                    <label htmlFor="sponsor-understands-checkbox-2">
                        I read the thing
                    </label>
                </div>
                <br />
                <button
                    disabled={(selectedPrizeTokenContract === null || challengeIndexToSponsor === null || userDidCheckBox === false)}
                    onClick={executeNftSponsorship}
                >
                    Send Sponsorship
                </button>
            </div>
        </div>
    );
}