import React from "react"
import { ethers } from "ethers"
import ObjectToTable from "../ObjectToTable"

/**
 *  @name BlockSearcher
 *  @date October 4th 2022
 *  @author William Doyle
 *  @description get blocknumber from user and display block data
 */
export default function BlockSearcher() {
    const [blockNumber, setBlockNumber] = React.useState<number>(0)
    const [block, setBlock] = React.useState<string>("{}")
    const [oblock, setOBlock] = React.useState<any>({})

    const isFirstLoad = React.useRef(true)

    React.useEffect(() => {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);
        (async () => {
            const blocknumber = await provider.getBlockNumber()
            setBlockNumber(blocknumber)
            isFirstLoad.current = false
        })()
    }, [])

    React.useEffect(() => {

        (async () => {
            if (isFirstLoad.current) 
                return
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const data = await provider.getBlock(blockNumber)
            setBlock(JSON.stringify(data, null, 2))
            setOBlock(data)
        })()
    }, [blockNumber])

    return <div className="Block-Searcher" style={{
        display: "flex",
        border: "1px solid black",
        borderRadius: "10px",
        padding: "10px",
        margin: "10px",
    }}>
        <div className="Block-Searcher-Input">
            <h2> Input Block Number Here </h2>
            <label htmlFor="block-searcher-input">Block Number</label>
            <input
                type="number"
                id="block-searcher-input"
                value={blockNumber}
                onChange={(e) => setBlockNumber(parseInt(e.target.value))}
            />
            <label htmlFor="block-searcher-input">{blockNumber}</label>
        </div>
        <div className="block-searcher-output" style={{
            whiteSpace: 'pre-wrap',
            border: "1px solid black",
            borderRadius: "10px",
            padding: "10px",
            margin: "10px",
        }}>
            <h2> Block { (oblock?.number ?? '0' ) } Details</h2>
            {
                block
            }
        </div>
    </div>
}