import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setShow } from '../../redux/slices/solutionSlice'
import { unsafeExport } from '../../functions/makeReceiptPDF'
import { mintSolution } from '../ListOfChallenges/functions'
import { ethers } from 'ethers'
import currentAuthUser from '../../functions/currentAuthUser'
import Title from '../Title/Title'
import CloseIcon from '@material-ui/icons/Close';

/**
 * helpClaimFunds
 * August 18th 2022
 * William Doyle
 */
async function helpClaimFunds(claimFrom_pri: string) {
    // console.log('STUB INSIDE helpClaimFunds')
    // const send_to = await currentAuthUser()
    // const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    // const w: ethers.Wallet = new ethers.Wallet(claimFrom_pri, provider)

    // const balance = await w.getBalance()
    // const feeData = await provider.getFeeData()

    // if (feeData.gasPrice === null)
    //     throw new Error('gasPrice is null')

    // const tx = {
    //     to: send_to,
    //     value: balance.sub(ethers.utils.parseEther('0.01')), // TODO: make this smarter
    //     gasPrice: feeData.gasPrice,
    // }

    // await w.signTransaction(tx)
    // await w.sendTransaction(tx)
}

/**
 * August 18th 2022
 * William Doyle
 * @returns {JSX.Element}
 */
export default function ProcessSolution(): JSX.Element {
    const dispatch = useDispatch()
    const cd = useSelector((state: any) => state.contractData.contractData) // contract data
    const solution = useSelector((state: any) => state.solutionInfo.solution)
    const show = useSelector((state: any) => state.solutionInfo.show)

    if (!show)
        return <> </>
    return <div className="process-solution">
        <div className="process-solution-header">

            <Title text={`🎈🥳 Solution Found to Challenge ${solution.challengeDataToShow.position}🎈🥳`} level={3} />
            <Title text='Press Mint to issue your NFT certificate and Claim Prize Money to collect your reward.' level={5} />
            <CloseIcon onClick={() => dispatch(setShow(false))} />
        </div>
        <div className="process-solution-body">

            <div className={'coloumn'}>
                <div className="row">
                    <div className='rowItem'>
                        <button
                            className="btn-type-2"
                            onClick={() => helpClaimFunds(solution.solution)}
                        >
                            Claim Prize Money
                        </button>
                    </div>

                    <div className='rowItem'>
                        <button
                            className="btn-type-2"
                            onClick={() => mintSolution(solution.solution, parseInt(solution.challengeDataToShow.position), new ethers.Contract(cd.address, cd.abi))}
                        >
                            Mint NFT Certificate
                        </button>
                    </div>

                    <div className='rowItem'>
                        <button
                            className="btn-type-2"
                            onClick={() => unsafeExport(solution.challengeDataToShow, solution.solution, cd)}
                        >
                            Download Solution As PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}