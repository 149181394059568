import React from "react"

export default function useOnClickOutside(ref: any, callback: () => void) {
    React.useEffect(() => {
        function onClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target))
                callback()
        }
        document.addEventListener('mousedown', onClickOutside)
        return () => document.removeEventListener('mousedown', onClickOutside)
    }, [ref, callback])
}