import React from 'react'
import { useSelector} from 'react-redux'
import { deserializeList, FullDetails } from './../functions/getChallengeDetails'

/**
 *  September 23rd 2022
 *  William Doyle
 *  access redux and show all the details of all the challenges
 *  @returns {FullDetails[]} details of all the challenges
 *  @todo: make this hook (or make a seperate hook to) set the value via a callback function similar to how useState is used
 */
export default function useAllChallengeDetails() : FullDetails[] {
    const challengeData = useSelector((state: any) => state.typeIIChallengeDetails.challenges)
    const [details, setDetails] = React.useState<FullDetails[]>([] as FullDetails[])

    // get all the details of all the challenges (from redux) 
    React.useEffect(() => {
        setDetails(deserializeList(challengeData as string))
    }, [challengeData])

    return details
}