import { ethers } from "ethers"
import ChallengeLogicFacet from "../contractData/TypeIIChallenges/ChallengeLogicFacet.json"
import LockWithLamport from "../contractData/TypeIIChallenges/LockWithLamport.json"
import NFTLogicFacet from "../contractData/TypeIIChallenges/NFTLogicFacet.json"
import SponsorLogicFacet from "../contractData/TypeIIChallenges/SponsorLogicFacet.json"
import Dollar from "../contractData/Dollar/Dollar.json"
import UniqueAsset from "../contractData/UniqueAsset/UniqueAsset.json"
import addresses from "../contractData/TypeIIChallenges/addresses.json"

const chain_name = "sepolia"
const address = addresses[chain_name]

export type CurrencyRecord = {
    amount_bn: ethers.BigNumber,
    amount_s: string,
    currencyAddress: string,

    currencySymbol: string | null,
    currencyName: string | null,
}

export type FullDetails = {
    id: number,
    contract_address: string,
    is_solved: boolean,
    publicKey: string,
    emptySignature: string,
    bitsize_bn: ethers.BigNumber,
    bitsize_s: string,
    bitsize_n: number, // this is safe because the max bitsize is 256
    smallPublicKey_bn: {
        x: ethers.BigNumber,
        y: ethers.BigNumber,
    },
    smallPublicKey_s: {
        x: string,
        y: string,
    },
    smallPublicKey_pari: string,
    customParameters_bn: {
        Gx: ethers.BigNumber,
        Gy: ethers.BigNumber,
        P: ethers.BigNumber,
    },
    customParameters_s: {
        Gx: string,
        Gy: string,
        P: string,
    },
    sponsorship_details: null | {
        nativeTokenAmount_bn: ethers.BigNumber,
        nativeTokenAmount_s: string,
        currencyRecords: CurrencyRecord[],
    },
    nft_details: null | {
        solvedBy: string,
        tkid_bn: ethers.BigNumber,
        tkid_s: string,
        owner: string,
        ownerBalance: number,
        is_locked: boolean,
        uri: string,
        // lamportPublicKeyHash: null | [string, string][],
        lamportPublicKeyHash: null | string,
        ts_mint: number, // timestamp of mint
        ts_mint_human: string,
        block_mint: number, // block number of mint
        block_explorer: string, // block explorer link to the token 
    }
}

export function serializeList(details: FullDetails[]): string {
    return JSON.stringify(details)
}

export function deserializeList(details: string): FullDetails[] {
    return JSON.parse(details)
}

export async function getEveryChallengesDetails(): Promise<FullDetails[]> {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const challengeLogicFacet = new ethers.Contract(address, ChallengeLogicFacet.abi, provider)
    const numChallenges = (await challengeLogicFacet.get_all_challenges()).length
    return await Promise.all(Array.from({ length: numChallenges }, (_, i) => i).map(i => getChallengeDetails(i)))
}

/**
 * @getChallengeDetails
 * @date September 16th 2022
 * @author William Doyle 
 */
export default async function getChallengeDetails(challenge_index: number): Promise<FullDetails> {
    if (address === undefined)
        throw new Error('no contract address found in .env file')

    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const challengeLogicFacet = new ethers.Contract(address, ChallengeLogicFacet.abi, provider)

    const details = await challengeLogicFacet.get_all_challenge_details(challenge_index)

    const full_details: FullDetails = {
        id: challenge_index,
        publicKey: details.publicKey,
        emptySignature: details.emptySignature,
        bitsize_bn: details.bitsize,         // having both bn and string versions helps reduce number of conversions later on 
        bitsize_s: details.bitsize.toString(), // not hex 
        bitsize_n: details.bitsize.toNumber(), // this is safe because the max bitsize is 256
        smallPublicKey_bn: {
            x: details.smallPublicKey.x,
            y: details.smallPublicKey.y,
        },
        smallPublicKey_s: {
            // x: `0x${details.smallPublicKey.x.toHexString().substring(3).padStart(64, '0')}`,
            // y: `0x${details.smallPublicKey.y.toHexString().substring(3).padStart(64, '0')}`,

            // x: `0x${details.smallPublicKey.x.toHexString().substring(3)}`,
            // y: `0x${details.smallPublicKey.y.toHexString().substring(3)}`,

            x: details.smallPublicKey.x.toHexString(),
            y: details.smallPublicKey.y.toHexString(),
        },
        // smallPublicKey_pari: `${details.smallPublicKey.x.toHexString().substring(3)}${details.smallPublicKey.y.toHexString().substring(3)}`,
        smallPublicKey_pari: `${details.smallPublicKey.x.toHexString().substring(2)}${details.smallPublicKey.y.toHexString().substring(2)}`,
        // smallPublicKey_pari: `${details.smallPublicKey.x.toHexString()}${details.smallPublicKey.y.toHexString()}`,
        customParameters_bn: {
            Gx: details.customParameters.Gx,
            Gy: details.customParameters.Gy,
            P: details.customParameters.P,
        },
        customParameters_s: {
            // Gx: `0x${details.customParameters.Gx.toHexString().substring(3).padStart(64, '0')}`,
            // Gy: `0x${details.customParameters.Gy.toHexString().substring(3).padStart(64, '0')}`,
            // P: `0x${details.customParameters.P.toHexString().substring(3).padStart(64, '0')}`,

            // Gx: `0x${details.customParameters.Gx.toHexString().substring(3)}`,
            // Gy: `0x${details.customParameters.Gy.toHexString().substring(3)}`,
            // P: `0x${details.customParameters.P.toHexString().substring(3)}`,
            
            Gx: details.customParameters.Gx.toHexString(),
            Gy: details.customParameters.Gy.toHexString(),
            P: details.customParameters.P.toHexString(),
            
        },
        is_solved: details.is_solved,

        nft_details: null,
        contract_address: address,
        sponsorship_details: null,
    }

    if (details.is_solved) {
        const p_solvedBy = challengeLogicFacet.solvedBy(challenge_index)
        const p_tkid = challengeLogicFacet.token_id_from_challenge_index(challenge_index)
        const nftLogicFacet = new ethers.Contract(address, NFTLogicFacet.abi, provider)
        const lockWithLamport = new ethers.Contract(address, LockWithLamport.abi, provider)
        const p_owner = nftLogicFacet.ownerOf(await p_tkid)
        const p_ownerBalance = nftLogicFacet.balanceOf(await p_owner)
        const p_tkuri = nftLogicFacet.tokenURI(await p_tkid)
        const p_is_locked = lockWithLamport.is_locked(await p_tkid)

        const tkid_s = (await p_tkid).toString() // not hex

        full_details['nft_details'] = {
            solvedBy: await p_solvedBy,
            tkid_bn: await p_tkid,
            tkid_s: tkid_s, // not hex
            owner: await p_owner,
            ownerBalance: (await p_ownerBalance).toNumber(), // toNumber
            uri: await p_tkuri,
            is_locked: await p_is_locked,
            lamportPublicKeyHash: null,
            ts_mint: details.mint_timestamp.toNumber(),
            ts_mint_human: new Date(details.mint_timestamp.toNumber() * 1000).toDateString(),
            block_mint: details.mint_blockNumber.toNumber(),
            block_explorer: `https://${chain_name}.etherscan.io/token/${address}?a=${tkid_s}`,
        }

        if (await p_is_locked)
            // full_details['nft_details']['lamportPublicKey'] = await lockWithLamport.getLamportPub(await p_tkid)
            full_details['nft_details']['lamportPublicKeyHash'] = await lockWithLamport.getLamportPkh(await p_tkid)
    }

    // check for native sponsorship amount


    const findSponsorshipDetails = async () => {
        const sponsorLogicFacet = new ethers.Contract(address, SponsorLogicFacet.abi, provider)
        const p_nativeTokenAmount = sponsorLogicFacet.get_native_prize_by_publicKey(details.publicKey)
        const nonNativeTokenDetails = await sponsorLogicFacet.get_erc20_prize_by_publicKey(details.publicKey)
        const nftTokenDetails = await sponsorLogicFacet.get_all_erc721_prizes_by_publicKey(details.publicKey)
        // console.log('Native Token Amount', (await p_nativeTokenAmount).toString())
        // console.log('Non Native Token Amount', nonNativeTokenDetails)
        // console.log('NFT Token Amount', nftTokenDetails)
        const processedNonNativeTokenDetails: CurrencyRecord[] = await Promise.all(nonNativeTokenDetails.map(async (record: any) => {

            // console.log(record)
            // const currencyContract = new ethers.Contract(record.currencyContract, Dollar.abi, provider)
            const currencyContract = new ethers.Contract(record.currencyAddress, Dollar.abi, provider)
            const currencyName = (await currencyContract.name()).toString()
            const currencySymbol = (await currencyContract.symbol()).toString()
            // console.log('Currency Name', currencyName)

            const processed: CurrencyRecord = {
                amount_bn: (record.amount as ethers.BigNumber),
                amount_s: record.amount.toString(), // not hex
                currencyAddress: record.currencyAddress,

                currencyName: currencyName,
                currencySymbol: currencySymbol,
            }
            return processed
        }))

        // find the erc721 details
        const processedNFTTokenDetails: CurrencyRecord[] = await Promise.all(nftTokenDetails.map(async (record: any) => {

            const nft: ethers.Contract = new ethers.Contract(record.nftAddress, UniqueAsset.abi, provider)
            const num = nftTokenDetails.reduce((acc: number, cur: any) => acc + (record.nftAddress === cur.nftAddress ? 1 : 0), 0)
            return {
                currencyAddress: record.nftAddress,
                currencyName: await nft.name(),
                currencySymbol: await nft.symbol(),
                amount_bn: ethers.BigNumber.from(num),
                amount_s: num.toString(),
            }

        }))



        return {
            nativeTokenAmount_bn: await p_nativeTokenAmount,
            nativeTokenAmount_s: (await p_nativeTokenAmount).toString(), // not hex
            currencyRecords: [...processedNonNativeTokenDetails, ...processedNFTTokenDetails],
        }
    }

    full_details['sponsorship_details'] = await findSponsorshipDetails()


    return full_details
}