export default function NotFound() {
    return <div className="NotFound" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        fontFamily: 'sans-serif',
        color: 'black',
        textShadow: '0px 0px 0px black',
    }}>
        <h1>404</h1>
        <h2>¯\_(ツ)_/¯</h2>
</div>

}