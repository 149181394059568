import React from "react";
import { useSelector } from 'react-redux'
import { deserializeList, FullDetails } from '../functions/getChallengeDetails'
import BlockSearcher from "../components/BlockSearcher/BlockSearcher";
import TokenWhiteLister from "../components/TokenWhiteLister/TokenWhiteLister";

function ChangeAdminInterface() {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        padding: '10px',
        margin: '10px',
        borderRadius: '10px'
    }}>
        <h1>Change The Owner Of This Contract</h1>
        <h4>Step 1: call `setNextOwner` to allow a value through the Lamport Guard</h4>
        <h4>Step 2: call `transferOwnership` with approved value, to preform the actual ownership transfer</h4>
    </div>
}

/*
    @date September 22nd 2022
*/
export default function Admin() {
    const focousTkn: number = useSelector((state: any) => state.typeIIChallengeDetails.focousTkn)
    const challengeData = useSelector((state: any) => state.typeIIChallengeDetails.challenges)
    const [details, setDetails] = React.useState<FullDetails[]>([] as FullDetails[])
    const [focousDetails, setFocousDetails] = React.useState<FullDetails | null>(null)

    // get all the details of all the challenges (from redux) 
    React.useEffect(() => {
        setDetails(deserializeList(challengeData as string))
    }, [challengeData])

    // get the details of the focous challenge (from redux)
    React.useEffect(() => {
        const temp: FullDetails | undefined = details.find((x: FullDetails) => {
            if (x.nft_details === null)
                return false
            return x.nft_details.tkid_s === focousTkn.toString()
        })
        if (temp === undefined)
            return
        setFocousDetails(temp)
    }, [focousTkn, details])

    return (
        <div>
            <h1>Admin</h1>
            <TokenWhiteLister />
            <BlockSearcher />
            <ChangeAdminInterface />
        </div>
    )
}