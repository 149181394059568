import React from 'react'
import Browse from '../components/Browse/Browse'
import Solve from '../components/Solve/Solve'
import Sponsor from '../components/Sponsor/Sponsor'
import {getEveryChallengesDetails, serializeList, } from '../functions/getChallengeDetails'
import { useDispatch } from 'react-redux'
import {setChallenges } from '../redux/slices/typeIIChallengeDetailsSlice'
import SolutionPlot from '../components/SolutionPlot/SolutionPlot'

type Option = {
    label: string,
    component: React.ReactElement,
}

const topLevelOptions: Option[] = [
    {
        label: 'BROWSE',
        component: <Browse />,
    },
    {
        label: 'SOLVE',
        component: <Solve />,
    },
    {
        label: 'SPONSOR',
        component: <Sponsor />,
    }
]

export default function TypeIIChallenges() {
    const dispatch = useDispatch()
    const [interactiveArea, setInteractiveArea] = React.useState(topLevelOptions[0].component)
    const [interactiveAreaIndex, setInteractiveAreaIndex] = React.useState(0)

    React.useEffect(() => {
        // get the challenges and store them in state
        async function load () {
            const chall = await getEveryChallengesDetails()
            console.log(`[TypeIIChallenges.tsx/useEffect/load] `, chall)
            dispatch(setChallenges(serializeList(chall)))
        }
        load()
    })

    return (
        <div className="TypeIIChallenges" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            fontFamily: 'monospace',
            color: 'black',
            textShadow: '0px 0px 0px black',
        }}>
            <div className="TypeII-graph" style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                backgroundColor: 'lightblue',
            }}>
                <SolutionPlot />
            </div>
            <div className="TypeII-top-level-options" style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
            }}>
                {
                    topLevelOptions.map((option, index) => {
                        return <div className="TypeII-top-level-option" key={index} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '10px',
                            padding: '100px',
                            backgroundColor: interactiveAreaIndex === index ? 'lightgrey' : 'grey',
                        }}
                            onClick={() => {
                                setInteractiveArea(option.component)
                                setInteractiveAreaIndex(index)
                            }}
                        >
                            {option.label}
                        </div>
                    })
                }
            </div>
            <div className="mode-wrapper" style={{
            }}>
                {interactiveArea}
            </div>
            <br/> {/* a little spacing at the bottom */}
            <br/>
        </div>
    )
}