import { ethers } from "ethers"
import signBytes from "../../functions/signBytes";
import NFTLogicFacet from "../../contractData/TypeIIChallenges/NFTLogicFacet.json"
import callContractFunction from "../../functions/callContractFunction"
import address from "../../constants/ADDRESS";

export default async function solveChallenge(challengePosition: string, challengePrivateKey: string, authUser: string) {
    const nftLogicFacet = new ethers.Contract(address, NFTLogicFacet.abi)
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const solutionWallet = new ethers.Wallet(challengePrivateKey, provider)
    const redeemerSig = await signBytes(authUser, solutionWallet)
    await callContractFunction(nftLogicFacet, 'mint', [redeemerSig, challengePosition])
}