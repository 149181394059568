import { ethers } from "ethers"
import getSigner from "../../functions/getSigner"
import SponsorLogicFacet from "../../contractData/TypeIIChallenges/SponsorLogicFacet.json"


const _executeNftSponsorship = (selectedPrizeTokenContract : ethers.Contract) => (prizeTokenId : string) => (challengeIndexToSponsor : string) => (sponsorMessage : string) => (diamondAddress: string) => async function executeNftSponsorship() {
    if (selectedPrizeTokenContract === null)
        return

    const signer = await getSigner()

    await selectedPrizeTokenContract
        .connect(signer)
        .approve(diamondAddress, prizeTokenId)

    await (new ethers.Contract(diamondAddress, SponsorLogicFacet.abi, signer))
        .sponsor_erc721(challengeIndexToSponsor, prizeTokenId, selectedPrizeTokenContract.address, sponsorMessage, { gasLimit: 1000000, value: sponsorMessage.length === 0 ? 0 : ethers.utils.parseEther('1') })
}

export default _executeNftSponsorship