
/*
adapted from https://medium.com/trabe/controlled-file-input-components-in-react-3f0d42f901b8
On September 22nd 2022 By William Doyle

*/

import React from "react";



const noop = () => { };

type Props = {
    value: File[],
    onChange: (files: File[]) => void,
    label: string,
}

const FileInput = ({ value, onChange = noop, label = `Select A File` , ...rest }: Props) => (
    <div style={{
        backgroundColor: 'lightblue',
        border: '1px solid black',
        // borderRadius: '10px',
        padding: '10px',
        margin: '10px',
    }}>
        {Boolean(value.length) && (
            <div>Selected files: {value.map(f => f.name).join(", ")}</div>
        )}
        <label>
            {/* Click to select some files... */}
            {`${label}`}
            <input
                {...rest}
                style={{ display: "none" }}
                type="file"
                onChange={e => {
                    if (e.target.files === null) {
                        console.error(`[FileInput.tsx/FileInput] e.target.files is null`)
                        return
                    }
                    onChange([...e.target.files]);
                }}
            />
        </label>
    </div>
);

export default FileInput;